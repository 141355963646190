#introContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center center; }

#introContainer:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#fff), to(#000000f5));
  background-image: -webkit-linear-gradient(top left, #fff, #000000f5);
  background-image: linear-gradient(to bottom right, #fff, #000000f5);
  opacity: 0.7; }

.middle {
  width: 400px;
  height: 400px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden; }
  @media only screen and (max-width: 700px) {
    .middle {
      padding: 0 10px; } }
  @media only screen and (max-width: 420px) {
    .middle {
      width: 300px;
      padding: 0 10px; } }

.text {
  font-family: Futura-Medium, "Futura Medium", "Century Gothic", CenturyGothic, "Apple Gothic", AppleGothic, "URW Gothic L", "Avant Garde", sans-serif;
  font-weight: light;
  font-size: 60px;
  color: #fff;
  text-shadow: 1px 0.5px #000;
  text-align: right;
  margin-right: -7px;
  opacity: 1;
  top: 20px;
  position: relative; }
  @media only screen and (max-width: 420px) {
    .text {
      font-size: 50px; } }

.hr {
  margin-top: 0px;
  height: 0px;
  width: 100%;
  margin-left: 0;
  background-color: #f95c4f; }

.hillrose {
  color: #f95c4f;
  text-shadow: 1px 0.5px #000; }

.desc {
  font-family: Futura-Medium, "Futura Medium", "Century Gothic", CenturyGothic, "Apple Gothic", AppleGothic, "URW Gothic L", "Avant Garde", sans-serif;
  font-weight: light;
  font-size: 60px;
  color: #fff;
  text-align: right;
  overflow: hidden; }
  @media only screen and (max-width: 420px) {
    .desc {
      font-size: 50px;
      margin-top: 0px; } }

.desc .descText {
  float: right;
  text-shadow: 1px 0.5px #000;
  margin-top: -9px; }

.contactForm h3 {
  font-size: 20px;
  margin: 20px 0px;
  color: white; }
