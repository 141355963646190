@import "variables";

.contactForm {
  max-width: 700px;
  width: 700px;
  margin-top: 50px;
  @media #{$tabletScreen} {
    width: 100%;
    margin-top: 20px;
  }
  @media #{$mobileScreen} {
    width: 100%;
    margin-top: 20px;
  }
}
.leftItem {
  @media #{$tabletScreen} {
    width: 100%;
  }
  @media #{$mobileScreen} {
    width: 100%;
  }
}
.rightItem {
  @media #{$tabletScreen} {
    width: 100%;
  }
  @media #{$mobileScreen} {
    width: 100%;
  }
}
.contactFormInput {
  //   background: red;
  border-radius: 1px !important;
  height: 45px !important;
}
.contactSelectResidence .ant-select-selection--single {
  border-radius: 1px !important;
  height: 45px;
  padding-top: 8px;
}

.contactBrokerRadio {
  .ant-radio-button-wrapper {
    height: 45px;
    line-height: 45px;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 1px solid #d9d9d9;
    border-radius: 0px;
  }
  .ant-radio-button-wrapper-checked:first-child {
    border-left: 1px solid #40a9ff;
  }
  .ant-radio-button-wrapper:last-child {
    border-radius: 0px;
  }
}
.contactBrokerItem {
  color: white;
  .ant-form-item-label {
    line-height: 45px;
    .ant-form-item-required {
      color: white !important;
    }
  }
}
.contactSubmit {
  border-radius: 0px;
  width: 45%;
  float: right;
  height: 45px;
  font-size: 16px;
  letter-spacing: 4px;
  @media #{$tabletScreen} {
    width: 100%;
  }
  @media #{$mobileScreen} {
    width: 100%;
  }
}

.alertMessageItem {
  border-radius: 0px;
}
