html,
body,
#root {
  width: 100%;
  height: 100%; }

body {
  /* font-family: "Share"; */
  font-size: 16px;
  font-weight: normal; }
  body,
  body * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

input {
  font: inherit; }

#introContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  background-size: cover;
  background-position: center center; }

#introContainer:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: -webkit-gradient(linear, left top, right bottom, from(#fff), to(#000000f5));
  background-image: -webkit-linear-gradient(top left, #fff, #000000f5);
  background-image: linear-gradient(to bottom right, #fff, #000000f5);
  opacity: 0.7; }

.middle {
  width: 400px;
  height: 400px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden; }
  @media only screen and (max-width: 700px) {
    .middle {
      padding: 0 10px; } }
  @media only screen and (max-width: 420px) {
    .middle {
      width: 300px;
      padding: 0 10px; } }

.text {
  font-family: Futura-Medium, "Futura Medium", "Century Gothic", CenturyGothic, "Apple Gothic", AppleGothic, "URW Gothic L", "Avant Garde", sans-serif;
  font-weight: light;
  font-size: 60px;
  color: #fff;
  text-shadow: 1px 0.5px #000;
  text-align: right;
  margin-right: -7px;
  opacity: 1;
  top: 20px;
  position: relative; }
  @media only screen and (max-width: 420px) {
    .text {
      font-size: 50px; } }

.hr {
  margin-top: 0px;
  height: 0px;
  width: 100%;
  margin-left: 0;
  background-color: #f95c4f; }

.hillrose {
  color: #f95c4f;
  text-shadow: 1px 0.5px #000; }

.desc {
  font-family: Futura-Medium, "Futura Medium", "Century Gothic", CenturyGothic, "Apple Gothic", AppleGothic, "URW Gothic L", "Avant Garde", sans-serif;
  font-weight: light;
  font-size: 60px;
  color: #fff;
  text-align: right;
  overflow: hidden; }
  @media only screen and (max-width: 420px) {
    .desc {
      font-size: 50px;
      margin-top: 0px; } }

.desc .descText {
  float: right;
  text-shadow: 1px 0.5px #000;
  margin-top: -9px; }

.contactForm h3 {
  font-size: 20px;
  margin: 20px 0px;
  color: white; }


.contactForm {
  max-width: 700px;
  width: 700px;
  margin-top: 50px; }
  @media only screen and (max-width: 700px) {
    .contactForm {
      width: 100%;
      margin-top: 20px; } }
  @media only screen and (max-width: 420px) {
    .contactForm {
      width: 100%;
      margin-top: 20px; } }

@media only screen and (max-width: 700px) {
  .leftItem {
    width: 100%; } }

@media only screen and (max-width: 420px) {
  .leftItem {
    width: 100%; } }

@media only screen and (max-width: 700px) {
  .rightItem {
    width: 100%; } }

@media only screen and (max-width: 420px) {
  .rightItem {
    width: 100%; } }

.contactFormInput {
  border-radius: 1px !important;
  height: 45px !important; }

.contactSelectResidence .ant-select-selection--single {
  border-radius: 1px !important;
  height: 45px;
  padding-top: 8px; }

.contactBrokerRadio .ant-radio-button-wrapper {
  height: 45px;
  line-height: 45px; }

.contactBrokerRadio .ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 0px; }

.contactBrokerRadio .ant-radio-button-wrapper-checked:first-child {
  border-left: 1px solid #40a9ff; }

.contactBrokerRadio .ant-radio-button-wrapper:last-child {
  border-radius: 0px; }

.contactBrokerItem {
  color: white; }
  .contactBrokerItem .ant-form-item-label {
    line-height: 45px; }
    .contactBrokerItem .ant-form-item-label .ant-form-item-required {
      color: white !important; }

.contactSubmit {
  border-radius: 0px;
  width: 45%;
  float: right;
  height: 45px;
  font-size: 16px;
  letter-spacing: 4px; }
  @media only screen and (max-width: 700px) {
    .contactSubmit {
      width: 100%; } }
  @media only screen and (max-width: 420px) {
    .contactSubmit {
      width: 100%; } }

.alertMessageItem {
  border-radius: 0px; }

@charset "UTF-8";
.navigation {
  position: relative;
  height: 70px;
  background: #262626; }

.brand {
  position: absolute;
  padding-left: 20px;
  float: left;
  line-height: 70px;
  text-transform: uppercase;
  font-size: 1.4em; }
  .brand a,
  .brand a:visited {
    color: #ffffff;
    text-decoration: none; }

.nav-container {
  max-width: 1000px;
  margin: 0 auto; }

nav {
  float: right; }
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    nav ul li {
      float: left;
      position: relative; }
      nav ul li a,
      nav ul li a:visited {
        display: block;
        padding: 0 20px;
        line-height: 70px;
        background: #262626;
        color: #ffffff;
        text-decoration: none; }
        nav ul li a:hover,
        nav ul li a:visited:hover {
          background: #2581dc;
          color: #ffffff; }
        nav ul li a:not(:only-child):after,
        nav ul li a:visited:not(:only-child):after {
          padding-left: 4px;
          content: " \25BE"; }
      nav ul li ul li {
        min-width: 190px; }
        nav ul li ul li a {
          padding: 15px;
          line-height: 20px; }

.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15); }

/* Mobile navigation */
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background: #262626;
  height: 70px;
  width: 70px; }

@media only screen and (max-width: 798px) {
  .nav-mobile {
    display: block; }
  nav {
    width: 100%;
    padding: 70px 0 15px; }
    nav ul {
      display: none; }
      nav ul li {
        float: none; }
        nav ul li a {
          padding: 15px;
          line-height: 20px; }
        nav ul li ul li a {
          padding-left: 30px; }
  .nav-dropdown {
    position: static; } }

@media screen and (min-width: 799px) {
  .nav-list {
    display: block !important; } }

#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px; }
  #nav-toggle span,
  #nav-toggle span:before,
  #nav-toggle span:after {
    cursor: pointer;
    border-radius: 1px;
    height: 5px;
    width: 35px;
    background: #ffffff;
    position: absolute;
    display: block;
    content: "";
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out; }
  #nav-toggle span:before {
    top: -10px; }
  #nav-toggle span:after {
    bottom: -10px; }
  #nav-toggle.active span {
    background-color: transparent; }
    #nav-toggle.active span:before, #nav-toggle.active span:after {
      top: 0; }
    #nav-toggle.active span:before {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    #nav-toggle.active span:after {
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }

article {
  max-width: 1000px;
  margin: 0 auto;
  padding: 10px; }

.sectionContainer {
  background: #334144ed; }

.Section {
  max-width: 1180px;
  margin: 0 auto;
  padding: 80px 54px; }
  @media only screen and (max-width: 700px) {
    .Section {
      padding: 20px 34px; } }
  @media only screen and (max-width: 420px) {
    .Section {
      padding: 20px; } }

.logoutButton {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 20px;
  color: white;
  font-size: 14px;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 6px 4px 6px 8px;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-transition: all 300ms;
  transition: all 300ms; }

.login-form {
  max-width: 300px;
  margin: 50px auto; }

.login-form-button {
  width: 100%; }

