@import "variables";
.sectionContainer {
  background: #334144ed;
}
.Section {
  max-width: 1180px;
  margin: 0 auto;
  padding: 80px 54px;
  @media #{$tabletScreen} {
    padding: 20px 34px;
  }
  @media #{$mobileScreen} {
    padding: 20px;
  }
}

.logoutButton {
  position: absolute;
  display: inline-block;
  right: 10px;
  top: 20px;
  color: white;
  font-size: 14px;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 6px 4px 6px 8px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 300ms;
}
