@import "~antd/dist/antd.css";

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  /* font-family: "Share"; */
  font-size: 16px;
  font-weight: normal;

  &,
  * {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

input {
  font: inherit;
}
